html{
    scroll-behavior:smooth
}
.section-header{
    position: relative;
    display: inline-block;
    font-size: 4vw;
    font-weight: 700;
    margin: 12vw;
}
.section-header:before{
    position: absolute;
    display: block;
    content:'';
    width: 90%;
    height: 2.2vw;
    bottom: 0px;
    left: 20%;
    background: mediumaquamarine;
    z-index: -1;
}

/* Personal Section*/
#personal-section{
    margin: 0vw 5vw;
}

#personal-headshot>img{
    border-radius: 50%;
}
#personal-body{
    display: flex;
    flex-direction: row;
}
#personal-headshot{
    width: 25%;
    
}
#personal-headshot>img{
    width: 100%;
    height: auto;
}
#personal-text{
    width: 75%;
    text-align: left;
    font-size: 2vw;
    padding-left: 3vw;
    padding-right: 3vw;
}
#personal-text>h6{
    width: 100%;
    font-size: 1.4em;
    margin-top: 10px;
    margin-bottom: 0px;
}

/* Skills Section*/
#skills-heading{
    display: flex;
    justify-content: center;
    margin: 8vw 6vw;
    border-top: 6px solid black;
    border-bottom: 6px solid black;
}
#skills-subheading{
    display:flex;
    justify-content: right;
    width: 50%;
    
}
#skills-subheading>img{
    width: 5vw;
    aspect-ratio: 1/1;
    margin-top: auto;
    margin-bottom: 1vw;
   
}
#skills-subheading>h2{
    display: inline;
    text-overflow: ellipsis;
    font-size: 3vw;
    margin-left: 5px;
    margin-right: 5px;
}
#skills-subheading-2{
    display:flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    padding-left: 4vw;
}
#download-button{
    display: flex;
    background-color: green;
    width: 55%;
    height: 5vw;
    border-radius: 1vw;
    color: white;
    border: 1px solid black;
    margin: auto 0px;
    align-items: center;
    text-decoration: none;
    
}
#download-button:hover{
    scale: 0.9;
    transition: 0.4s;
}
#download-button>p{
    width: 75%;
    font-size: 2vw;
    padding-left: 1vw
}
#download-button>div{
    width: 25%;
    height: auto;
    object-fit: fill;
    display: flex;
    justify-content: center;
}
#download-button>div>img{
    width: 50%;
    height: auto;
    
}

.skill-container{
    position: relative;
    width: 12vw;
    font-size: 1.5vw;
    aspect-ratio: 1/1;
    border: 0.2vw solid darkgreen;
    border-radius: 10%;
    text-align: center;
    margin: auto;
}
.skill-icon{
    width: 70%;
    height: auto;
    padding: 4%;
}
.skill-container>p{
    position: absolute;
    bottom: 0px;
    margin: auto;
    align-self: center;
    width: 100%;
}

/* Project Section*/
#projects-body{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}


/* Contact Section */
#contact-body{
    display: flex;
    justify-content: space-evenly;
    vertical-align: middle;
    margin-bottom: 15vw;
    margin-left: 30vw;
    margin-right: 30vw;
}
#contact-body>a:hover{
    color: gray;
}
#contact-body>a>img{
    width: 10vw;
    aspect-ratio: 1/1;
    padding: 1vw;
}
#contact-body>a>img:hover{
    scale: 0.9;
    transition: 0.4s;   
}
#contact-body>div{
    margin: auto 10px;
}
.black-dot{
    margin: auto 0px;
    width: 2vw;
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: black;
}

/* Grid */
.flex-grid{
    display: flex;
    flex-wrap:wrap;
    margin-top: 3vw;
}
.flex-grid:nth-child(even){
    margin-left: 20vw;
    margin-right: 20vw;
}
.flex-grid:nth-child(odd){
    margin-left: 15vw;
    margin-right: 15vw;
}

@media only screen and (max-width: 576px){
    .section-header{
        font-size: 6vw;
    }
    #skills-subheading{
        width: 65%;
        justify-content: center;
        margin: 2vw 0vw;
    } 
    #skills-subheading>h2{
        font-size: 4vw;
    }  
    #skills-subheading-2{
        padding: 0px;
    }
    #skills-subheading-2>a{
        width: 100%;
        height: 7vw;

    }
    .skill-container{
        width: 20vw;
    }
    .flex-grid{
        margin-top: 2vw;
    }
    .flex-grid:nth-child(even){
        margin-left: 10vw;
        margin-right: 10vw;
    }
    .flex-grid:nth-child(odd){
        margin-left: 5vw;
        margin-right: 5vw;
    }
    .caption{
        font-size: 3vw;
    }

    #personal-text{
        font-size: 3vw;
    }
    #personal-body{
        flex-direction: column;
        align-items: center;
    }
    #download-button>p{
        font-size: 2.8vw;
        padding: 0vw 2vw;
    }
    #contact-body>a>img{
        width: 15vw;
    }

}


@keyframes bounce-2 {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(10px); }
    100% { transform: translateY(0); }
}

