.project-card{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 40vw;
    border: 0.2vw solid green;
    margin: 20px;
}

.project-image>img{
    width: 100%;
    height: auto;
}
.project-body{
    width: 100%;
    border-top: 0.1vw solid black;
    box-shadow: 0px -0.2vw 3px gray;
    text-align: center;
    align-items: center;
    background-color: ivory;
}
.project-body>h2{
    width: 100%;
    font-size: 2vw;
}
.project-body>p{
    font-size: 1.6vw;
}
.buttons-container{
    display: flex;
    width: 100%;
    margin-bottom: 2vw;
}
.buttons-container>a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 4vw;
    margin: 1vw;
    font-size: 1.6vw;
    border: 0.1vw solid black;
    border-radius: 0.6vw;
    text-decoration: none;
    color: black;
    box-shadow: 0.2vw 0.2vw 0.6vw black;
}
.buttons-container>a:hover{
    scale: 0.9;
    transition: 0.4s;
}
.buttons-container>a>img{
    width: 2vw;
    aspect-ratio: 1/1;
    margin-left: 0.5vw;
    margin-right: -0.9vw;
}
.live-button{
    background-color: rgba(188, 161, 104, 0.74);
}
.github-button{
    background-color: rgba(161, 184, 168, 1);
}

@media only screen and (max-width: 576px){
    .project-card{
        position: relative;
        width: 250px;
    }
    .project-body>h2{
        font-size: 0.8em;
    }
    .project-body>p{
        font-size: 0.8em;
    }
    .buttons-container>a{
        height: 25px;
        font-size: 0.7em;
        border-radius: 5px;
        margin: 5px;
    }
    .buttons-container>a>img{
        width: 15px;
    }
}

/* Mobile */
@media only screen and (max-width: 576px){
    .project-card{
        width: 70vw; 
    }
}