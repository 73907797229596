/* Navbar */
#navbar{
    display: flex;
    position: relative;
    justify-content: flex-end;
    height: 15vw;
    background-color: mediumaquamarine;
}
#nav-brand{
    display: flex;
    position: absolute;
    align-items: center;
    width: 25vw;
    left: 0px;
    height: 100%;
}
#brand-text{
    display: flex;
    justify-content: flex-end;
    width: 50%;
    font-size: 6vw;
    margin-left: 3vw;
    margin-right: -1vw;
}
#brand-image{
    width: 40%;
    aspect-ratio: 1/1;
    rotate: 270deg;
    margin-left: 1vw;
    margin-top: 0.2vw
}
#brand-image>img{
    width: 5vw;
    aspect-ratio: 1/1;
}
#nav-menu{
    display: flex;
    justify-content: space-evenly;
    list-style: none;
    width: 55%;
    font-size: 2.4vw;
    vertical-align: middle;
}
.nav-item{
    display: flex;
}
.nav-item>a:hover{
    color: gray;
    scale: 0.95;
}
.nav-item>a{
    margin: auto;
    text-decoration: none;
    color: black;
    font-weight: 500;
}

@media only screen and (max-width: 576px){
    #navbar{
        height: 20vw
    }
    #nav-menu{
        width: 65%;
        font-size: 3.6vw;
    }
    #brand-text{
        font-size: 8vw;
    }
    #brand-image>img{
        margin-right: 0.6vw;
        width: 6.4vw
    }
}
