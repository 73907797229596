#footer{
    position: relative;
    display: flex;
    justify-content:center;
    width: 100%;
    height: 15vw;
    background-color: mediumaquamarine;
}
#footer>a{
    width: 8vw;
    height: 8vw;
    position: absolute;
    object-fit: fill;
    rotate: 180deg;
    top: -4vw;
    background-color: mediumaquamarine;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
}
#footer>a:hover{
    animation-name: bounce;
    animation-timing-function: ease;
}
#footer>a>img{
    width: 100%;
    aspect-ratio: 1/1;
}
#footer>p{
    margin: 0px;
    font-size: 2vw;
    font-weight: 450;
    position: absolute;
    top: 40%;
}

@media only screen and (max-width: 576px){
    #footer{
        height: 20vw;
    }
    #footer>p{
        font-size: 3vw;
    }
    #footer>a{
        width: 10vw;
        height: 10vw;
        top: -5vw;
    }

}

@keyframes bounce {
    0%   { transform: translateY(0); }
    50%  { transform: translateY(10px); }
    100% { transform: translateY(0); }
}